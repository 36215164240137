<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .el-sel-width {
    width: 100%;
  }

  .list-table {
    .dvcimg {
      width: 100px;
      /*height: 100px;*/
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>滤芯寿命规则</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="querySurplusRules">
      <el-col :span="24" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-select v-model="filters.code" @change="querySurplusRules">
              <el-option
                v-for="(item, index) in surplusTypeList"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              style="width: 364px"
              v-model="filters.key"
              placeholder="请输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-button type="primary" @click="getDeviceByName">搜索</el-button> -->
            <el-button type="primary" @click="querySurplusRules">
              搜索
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="tableData"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column
          prop="ruleName"
          :show-overflow-tooltip="true"
          label="规则命名"
        ></el-table-column>
        <el-table-column
          prop="calculation"
          min-width="95"
          :show-overflow-tooltip="true"
          label="计算方式"
        ></el-table-column>
        <el-table-column
          prop="maxValue"
          min-width="95"
          :show-overflow-tooltip="true"
          label="最大值"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          min-width="95"
          :show-overflow-tooltip="true"
          label="备注"
        ></el-table-column>

        <el-table-column
          prop="updateTime"
          min-width="100"
          :show-overflow-tooltip="true"
          :formatter="formatTime"
          label="更新时间"
        ></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              @click="handleEdit(scope.$index, scope.row)"
              :disabled="!editPermission"
              size="small"
            >
              修改
            </el-button>
            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <!-- <router-link to="/device/detail"> -->
        <el-button @click="dialogShow = true" type="primary">新增</el-button>
        <!-- </router-link> -->
        <el-button
          type="danger"
          :disabled="!this.sels.length > 0"
          @click="batchRemove"
        >
          批量删除
        </el-button>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      :title="isEdit ? '修改' : '新增滤芯计算规则'"
      :close-on-click-modal="false"
      size="small"
      :visible.sync="dialogShow"
    >
      <el-form
        ref="formContent"
        :model="surplusInfo"
        label-width="90px"
        :rules="rules"
      >
        <el-form-item label="滤芯类型" prop="code">
          <el-select v-model="surplusInfo.code" style="width: 100%">
            <el-option
              v-for="(item, index) in surplusTypeList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规则命名:" prop="ruleName">
          <el-input
            v-model="surplusInfo.ruleName"
            placeholder="请输入规则名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="计算方式:">
          <el-input
            v-model="surplusInfo.calculation"
            placeholder="请输入计算方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="低效滤网:" prop="lowRule">
          <el-select v-model="surplusInfo.lowRule" style="width: 100%">
            <el-option
              v-for="item in [
                { value: 1, label: '是' },
                { value: 0, label: '否' },
              ]"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最大值:" prop="maxValue">
          <el-input
            v-model="surplusInfo.maxValue"
            placeholder="请输入滤芯寿命最大值"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            :rows="5"
            :maxlength="200"
            v-model="surplusInfo.remarks"
            placeholder="请输入设备描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDvc">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    querySurplusRules,
    createSurplusRules,
    updateSurplusRules,
    delSurplusRules,
  } from "../../../services/device";
  import { updateDefaultDevice } from "../../../services/ucenter";
  import { getAllCustomers } from "../../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import DeviceDetail from ".././detail.vue";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import { valiNoEmptyNumber } from "../../../utils/validator/element";

  export default {
    data() {
      return {
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        dataList: [],
        tableData: [],
        filters: {
          key: "",
          code: "1",
        },
        //下面是对话框中的信息
        surplusInfo: {
          code: "",
          ruleName: "",
          calculation: "",
          remarks: "",
          maxValue: "",
          lowRule: "",
        },
        listLoading: false,
        sels: [], //列表选中列
        dialogShow: false,
        surplusTypeList: [
          { code: "1", name: "一层滤芯寿命" },
          { code: "2", name: "二层滤芯寿命" },
          { code: "3", name: "三层滤芯寿命" },
          { code: "4", name: "四层滤芯寿命" },
        ],
        rules: {
          code: {
            required: true,
            message: "请选择",
            trigger: "change",
          },
          lowRule: {
            required: true,
            message: "请选择",
            trigger: "change",
          },
          ruleName: {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          maxValue: {
            validator: valiNoEmptyNumber,
            trigger: "blur",
          },
        },
      };
    },
    components: {
      "device-detail": DeviceDetail,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
      // tableData() {
      //   return this.dataList.filter(i => i.code == this.filters.code)
      // }
    },
    watch: {
      dialogShow(newval) {
        if (!newval) {
          this.surplusInfo = {
            code: "",
            ruleName: "",
            calculation: "",
            remarks: "",
            maxValue: "",
            lowRule: "",
          };
        }
      },
    },
    methods: {
      submitDvc() {
        this.$refs.formContent.validate((valid) => {
          if (valid) {
            this.dialogShow = false;
            if (this.surplusInfo.lowRule === "true") {
              this.surplusInfo.lowRule = true;
            }
            if (this.surplusInfo.lowRule === "false") {
              this.surplusInfo.lowRule = false;
            }

            //console.log(this.surplusInfo)
            this.updateDvcType(this.surplusInfo);
          }
        });
      },
      /**
       * 更新滤芯规则
       */
      async updateDvcType(obj) {
        let res = null;
        if (this.isEdit) {
          res = await updateSurplusRules(obj);
        } else {
          res = await createSurplusRules(obj);
        }
        if (res && res.errorCode === 0) {
          this.querySurplusRules();
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        }
        this.isEdit = false;
      },

      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Device:update";
        });
      },
      formatTime(row) {
        return row && row.updateTime
          ? moment(row.updateTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delSurplusRules(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.querySurplusRules();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.dialogShow = true;
        // console.log(row)
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedRules = Object.assign({}, row);
        this.surplusInfo = {
          id: choosedRules.id,
          ruleName: choosedRules.ruleName,
          calculation: choosedRules.calculation,
          remarks: choosedRules.remarks,
          maxValue: choosedRules.maxValue,
          lowRule: choosedRules.lowRule,
          code: choosedRules.code,
        };
        if (this.surplusInfo.lowRule === true) {
          this.surplusInfo.lowRule = 1;
        }
        if (this.surplusInfo.lowRule === false) {
          this.surplusInfo.lowRule = 0;
        }
      },

      /**
       * 定义序号的排序
       */
      sortDeviceByTime({ column, prop, order }) {
        if (prop === "index") {
          if (order === "ascending") {
            this.pager.sort = "updateTime,asc";
          } else {
            this.pager.sort = "updateTime,desc";
          }
          this.queryDevices();
        }
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        console.log(ids);
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delSurplusRules(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.querySurplusRules();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据设备名筛选
       */
      getDeviceByName() {
        this.querySurplusRules();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.querySurplusRules();
        if (this.loaded) {
          this.pager.page = page;
          // window.router.replace({path: 'kind', query: {page: page}})
          this.querySurplusRules();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.querySurplusRules();
      },

      /**
       * 获取产品型号列表
       */
      async querySurplusRules() {
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters);
        // if (Object.prototype.toString.call(searchParam.isProxy) != '[object Boolean]') {
        //   delete searchParam.isProxy;
        // }
        // const responseData = await querySurplusRules(searchParam, this.pager);
        const responseData = await querySurplusRules(searchParam, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.tableData = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 处理禁用还是启用
       * @param index 当先列的下标
       * @param row   当前列的数据对象
       * @param disabled Boolean true调用禁用  false调用启用
       */
      async handleDisabled(index, row, disabled) {
        let disableData = null;
        if (disabled) {
          disableData = await disabledDevice(row.id);
        } else {
          disableData = await enabledDevice(row.id);
        }
        if (disableData.errorCode == 0 && disableData.data == "done") {
          row.locked = disabled;
          Notification.success({
            title: "成功",
            message: disabled ? "禁用成功" : "启用成功",
          });
        }
      },
    },
    created() {
      this.querySurplusRules();
    },
  };
</script>
